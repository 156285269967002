.navbar-side {
  position: absolute;
  top: 50px;
  left: 0;
  border: 0;
  z-index: 20;
  height: 100%;
  width: 225px;
  background-color: #34495E;

  @media screen and (max-width: 991px) {
    position: relative;
    top: 0;
    width: 100%;
  }

  @media screen and (min-width: 992px) {
    .navbar-collapse:not(.show) {
      display: block;
    }
  }

  .accordion {

    .card {
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid #2c3e50 !important;
      border-right: 1px solid #34495e !important;

      .card-header {
        padding: 0;
        border: 0;
      }

      .navlink {
        display: block;
        width: 225px;
        padding: 15px;
        color: white;
        background-color: #34495e;
        line-height: 20px;
        text-decoration: none;
        font-size: 13px;
        border-radius: 0;
        text-align: left;

        &:hover {
          background-color: #3d566e;
        }

        &.active {
          background-color: #2980b9;

          &:hover {
            background-color: #4592C1;
          }
        }

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }

      .card-body {
        padding: 0;

        ul {
          margin: 0;
          padding: 0;
          list-style-type: none;

          li {
            display: block;
            background-color: #ecf0f1;

            a {
              display: block;
              width: 225px;
              padding: 10px 20px;
              color: #333;
              line-height: 20px;
              text-decoration: none;
              font-size: 13px;

              &:hover {
                background-color: #e0e7e8;
              }

              @media screen and (max-width: 991px) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  ul.side-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: block;
      background-color: #7f7f7f;
      border-bottom: 1px solid #2c3e50;

      a {
        display: block;
        width: 225px;
        padding: 15px;
        color: white;
        background-color: #34495e;
        line-height: 20px;
        text-decoration: none;
        font-size: 13px;

        &:hover {
          background-color: #3d566e;
        }

        &.active {
          background-color: #2980b9;

          &:hover {
            background-color: #4592C1;
          }
        }

        @media screen and (max-width: 991px) {
          width: 100%;
        }
      }
    }
  }
}
