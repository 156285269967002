.page-title {
  padding: 30px 0;

  h1 {
    margin: 0;
    padding-bottom: 15px;
    border-bottom: 15px solid #e0e7e8;
    font-size: 26px;
  }
}
