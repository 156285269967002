.login-banner {
  margin: 50px 0;
  color: white;
}

.login-card {
  border: 1px solid #2980b9 !important;

  .card-header {
    color: white;
    font-size: 16px;
    font-weight: bold;
    background: #2980b9;
    display: flex;
    align-items: center;
  }
}
