.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #34495E;
  z-index: 10;
  
  .credits {
  	color: #668FB7;
  	font-family: Verdana, sans-serif;
  	font-size: 10px;
  	padding: 3px;
  }

  a {
    color: #428bca;

    &:hover {
      text-decoration: underline;
      color: #428bca;
    }
  }
}
